import {DateField, useRecordContext} from "react-admin";

export const DateFieldForTimestamp = (props: any) => {
    const record = useRecordContext()
    const value = record[props.source]

    const recordWithTimestampAsInteger = {
        [props.source]: new Date(value * 1000)
    };
    return <DateField {...props} record={recordWithTimestampAsInteger} />
}