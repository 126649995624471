import {Call} from "./utils";
import BaseApiProvider from "./BaseApiProvider";

export interface SendNotificationData {
    async: boolean;
    code: string;
    data: string;
}

const MessengerApi = {
    ...BaseApiProvider,

    getChannelList: async () => {
        return await Call("POST", "/triggers/channel-list")
            .then((result) => {
                return result?.values;
            })
        ;
    },

    sendNotifications: async (data: SendNotificationData) => {
        return await Call("POST", "/messengers/send", data)
            .then((result) => {
                return result.jobId;
            })
        ;
    },

    toggleTriggerEnabled: async (id: any, enable: boolean) => {
        return await Call("POST",  `/triggers/${id}/${enable ? "enable" : "disable"}`)
            .then(() => {
                return true;
            })
        ;
    },

    toggleTemplatePublish: async (id: any, publish: boolean) => {
        return await Call("POST",  `/templates/${id}/${publish ? "publish" : "unpublish"}`)
            .then(() => {
                return true;
            })
        ;
    }
};

export default MessengerApi;