import {
  BooleanField,
  Datagrid,
  List,
  TextField,
  BooleanInput,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  Show,
  SimpleShowLayout, TopToolbar, ListButton, EditButton, ShowButton, FilterButton, CreateButton,
} from "react-admin";
import { ChannelListInput } from "../fields/ChannelListInput";
import {resourceManager} from "../../helpers/Resources";
import OutboxOutlinedIcon from "@mui/icons-material/OutboxOutlined";
import {JsonField, JsonInput} from "../fields/JsonField";
import React from "react";

export const showAction = () => (
  <>
    <Show actions={
      <TopToolbar>
        <ListButton />
        <EditButton />
      </TopToolbar>
    }>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="label" />
        <TextField source="channel" />
        <JsonField source="discriminators" />
        <TextField source="adapter" />
        <JsonField source="options" jsonString />
        <BooleanField source="enabled" />
      </SimpleShowLayout>
    </Show>
  </>
);

export const editAction = () => (
  <>
    <Edit actions={
      <TopToolbar>
        <ListButton />
        <ShowButton />
      </TopToolbar>
    }>
      <SimpleForm>
        <TextInput source="label" fullWidth />
        <JsonInput source="discriminators" fullWidth />
        <TextInput source="adapter" fullWidth />
        <JsonInput source="options" jsonString />
        <BooleanInput source="enabled" fullWidth />
      </SimpleForm>
    </Edit>
  </>
);

export const createAction = () => (
  <>
    <Create actions={
      <TopToolbar>
        <ListButton />
      </TopToolbar>
    }>
      <SimpleForm>
        <TextInput source="label" fullWidth />
        <JsonInput source="discriminators" fullWidth />
        <TextInput source="adapter" fullWidth />
        <JsonInput source="options" jsonString />
        <BooleanInput source="enabled" fullWidth />
        <ChannelListInput source="channel" />
      </SimpleForm>
    </Create>
  </>
);

const filters = [
  <ChannelListInput source="channel" />,
];

const Actions = () => (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
    </TopToolbar>
);

const listAction = () => (
    <List exporter={false} actions={<Actions />} filters={filters} >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="label" />
      <TextField source="channel" />
      <TextField source="adapter" />
      <BooleanField source="enabled" />
    </Datagrid>
  </List>
);

resourceManager.addResource('senders', OutboxOutlinedIcon,{
  list: listAction,
  create: createAction,
  show: showAction,
  edit: editAction,
})
