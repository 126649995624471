import React from "react";
import {
    Create,
    Datagrid,
    EditButton,
    List, ListButton,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField, TopToolbar, ShowButton,
} from "react-admin";
import { Edit, ReferenceInput, SimpleForm, TextInput } from "react-admin";
import {resourceManager} from "../../helpers/Resources";
import NotificationImportantOutlined from "@mui/icons-material/NotificationImportantOutlined";
import {JsonField, JsonInput} from "../fields/JsonField";
import SendNotificationButton from "../buttons/SendNotificationButton";

export const editAction = () => (
  <>
    <Edit actions={
        <TopToolbar>
            <ListButton />
            <ShowButton />
        </TopToolbar>
    }>
      <SimpleForm>
        <TextInput source="label" fullWidth />
        <TextInput source="description" fullWidth />
        <ReferenceInput source="groupId" reference="notification-groups" fullWidth />
        <TextInput source="ttl" fullWidth />
        <JsonInput source="additionalData" jsonString />
        <TextInput source="dataSchema" fullWidth multiline rows={10} />
        <TextInput source="dataExample" fullWidth multiline rows={10} />
      </SimpleForm>
    </Edit>
  </>
);

export const createAction = () => (
  <>
    <Create actions={
        <TopToolbar>
            <ListButton />
        </TopToolbar>
    }>
      <SimpleForm>
        <TextInput source="label" fullWidth />
        <TextInput source="description" fullWidth />
        <TextInput source="code" fullWidth />
        <ReferenceInput source="groupId" reference="notification-groups" fullWidth />
        <TextInput source="ttl"  fullWidth/>
        <JsonInput source="additionalData" jsonString />
        <TextInput source="dataSchema" fullWidth multiline rows={10} />
        <TextInput source="dataExample" fullWidth multiline rows={10} />
      </SimpleForm>
    </Create>
  </>
);

export const showAction = () => (
  <>
      <Show actions={
          <TopToolbar>
              <ListButton />
              <EditButton />
              <SendNotificationButton />
          </TopToolbar>
      }>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="label" />
        <TextField source="description" />
        <TextField source="code" />
        <ReferenceField source="groupId" reference="notification-groups" link="show" />
        <TextField source="ttl" />
        <JsonField source="additionalData" jsonString />
        <JsonField source="dataSchema" jsonString />
        <JsonField source="dataExample" jsonString />
      </SimpleShowLayout>
    </Show>
  </>
);

const filters = [
    <TextInput source="label" />,
    <TextInput source="code" />,
];

const listAction = () => (
    <List exporter={false} filters={filters} >
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="label" />
            <TextField source="code" />
            <ReferenceField source="groupId" reference="notification-groups" link="show" />
        </Datagrid>
    </List>
);

resourceManager.addResource('notifications', NotificationImportantOutlined, {
    list: listAction,
    create: createAction,
    show: showAction,
    edit: editAction,
})
