import {
  ArrayField,
  Datagrid,
  FilterButton,
  List, ListButton,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput, SelectInput,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import {} from "react-admin";
import {resourceManager} from "../../helpers/Resources";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import {JsonField} from "../fields/JsonField";
import React from "react";
import {DateFieldForTimestamp} from "../fields/DateFieldForTimestamp";

const showAction = () => {
  return (
  <>
    <Show actions={
      <TopToolbar>
        <ListButton />
      </TopToolbar>
    }>
      <SimpleShowLayout>
        <TextField source="id"/>
        <JsonField source="data" jsonString />
        <ReferenceField source="triggerId" reference="triggers" link="show" />
        <TextField source="notificationJobId" />
        <ArrayField source="envelopes">
          <SimpleShowLayout>
            <Datagrid bulkActionButtons={false} hover={false}>
              <TextField source="locale"/>
              <TextField source="timezone"/>
              <ReferenceField source="templateId" reference="templates" link="show" />
              <ReferenceField source="senderId" reference="senders" link="show" />

              <ReferenceArrayField source="recipientIds" reference="recipients" label="Recipients">
                <Datagrid bulkActionButtons={false} hover={false} sx={{
                  '& .RaDatagrid-thead': {
                    display: 'none',
                  },
                }}>
                  <TextField source="userId" />
                  <ShowButton />
                </Datagrid>
              </ReferenceArrayField>

            </Datagrid>
            <Datagrid bulkActionButtons={false} hover={false}>
              <JsonField source="receipts" />
            </Datagrid>
          </SimpleShowLayout>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  </>
  );
};

const filters = [
  <ReferenceInput source="triggerId" reference="triggers" />,
  <TextInput source="notificationJobId" />,
  <ReferenceInput source="recipientId" reference="recipients" />,
  <ReferenceInput source="senderId" reference="senders" />,
  <TextInput source="remoteId" />,
  <SelectInput source="failed" choices={[
    { id: 'Y', name: 'Yes' },
    { id: 'N', name: 'No' },
  ]} />,
];

const Actions = () => (
    <TopToolbar>
      <FilterButton />
    </TopToolbar>
);

const listAction = () => (
    <List exporter={false}
          actions={<Actions />}
          filters={filters}
          sort={{ field: 'createdAt', order: 'DESC' }}
          className="always-visible-toolbar">
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <DateFieldForTimestamp source="createdAt" showTime={true} />
        <TextField source="notificationJobId" />
        <ReferenceField source="triggerId" reference="triggers" link="show" />
      </Datagrid>
    </List>
);

resourceManager.addResource('messages', MessageOutlinedIcon, {
  list: listAction,
  show: showAction,
})