import {TextInput, useRecordContext} from 'react-admin';
import {JsonField as BaseJsonField, JsonInput as BaseJsonInput} from "react-admin-json-view";

function prepareRecord(source: string, record: any, jsonString: any): any {
    if (record === undefined || record === null) {
        return record
    }

    if (record[source] === undefined || (source in record && record[source].length === 0)) {
        record[source] = jsonString ? "{}" : {};
    }

    if (jsonString) {
        const raw = JSON.parse(record[source]);
        record[source] = JSON.stringify(raw, null, 2);
    }

    return record[source]
}

export const JsonField = ({ source, jsonString }: any) => {
    prepareRecord(source, useRecordContext(), jsonString);
    return (
        <BaseJsonField source={source} jsonString={jsonString} reactJsonOptions={{
            displayDataTypes: false,
            enableClipboard: false,
            defaultValue: {},
            name: null,
        }} />
    );
};

export const JsonInput = ({ source, jsonString }: any) => {
    prepareRecord(source, useRecordContext(), jsonString);
    if (!jsonString) {
        return (
            <BaseJsonInput source={source} jsonString={jsonString} reactJsonOptions={{
                displayDataTypes: false,
                enableClipboard: false,
                defaultValue: {},
                name: null,
            }} />
        );
    }

    return (
        <TextInput source={source} multiline rows={10} />
    );
};

