import {BuildSearchBody, Call, FormatSearchResult, UnwrapData, WrapData} from "./utils";

const BaseApiProvider = {
    getOne: (resource: any, params: any) => {
        return Call("GET", `${resource}/${params.id}`)
            .then(UnwrapData(resource))
        ;
    },

    getList: (resource: any, params: any) => {
        return Call("POST", `${resource}/search`, BuildSearchBody(params))
            .then((result) => {
                return FormatSearchResult(result)
            })
        ;
    },

    getMany: (resource: any, params: any) => {
        return BaseApiProvider.getList(resource, {
            pagination: { page: 1, perPage: 100 },
            sort: { field: "id", order: "ASC" },
            filter: {
                ids: params.ids,
            }
        })
    },

    getManyReference: (resource: any, params: any) => {
        return BaseApiProvider.getList(resource, params)
    },

    create: (resource: any, params: any) => {
        return Call("POST", `${resource}`, WrapData(resource, params.data))
            .then(UnwrapData(resource))
        ;
    },

    update: (resource: any, params: any) => {
        return Call("PUT", `${resource}/update/${params.id}`, WrapData(resource, params.data))
            .then(UnwrapData(resource))
        ;
    },

    updateMany: (resource: any, params: any) => {
        throw new Error("Function updateMany is not supported.");
    },

    delete: (resource: any, params: any) => {
        return Call("GET", `${resource}/${params.id}`)
            .then((result) => {
                return result.deleted
            })
        ;
    },

    deleteMany: (resource: any, params: any) => {
        throw new Error("Function deleteMany is not supported.");
    }
};

export default BaseApiProvider;