import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
import {useNotify, Button, useRecordContext} from 'react-admin';
import {Api} from "../../provider/Api";
import SendIcon from "@mui/icons-material/Send";

const SendNotificationButton = () => {
    const [open, setOpen] = useState(false);
    const [async, setAsync] = useState(false);
    const [data, setData] = useState('');
    const record = useRecordContext();
    const notify = useNotify();

    const handleClick = async () => {
        const jobId = await Api.sendNotifications({
            async: async,
            data: data,
            code: record.code,
        })

        if (jobId === undefined) {
            notify(`The notification could not be created.`)
        } else {
            notify(`Notification job "${jobId}" created.`)
        }

        setOpen(false)
    }

    if (record !== undefined && "dataExample" in record && record.dataExample.length > 0 && data.length === 0) {
        const raw = JSON.parse(record.dataExample);
        record.dataExample = JSON.stringify(raw, null, 2);
        setData(record.dataExample)
    }

    return (
        <>
            <Button onClick={() => setOpen(true)} endIcon={<SendIcon />} label="Send..." />
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Send a notification <code>{ record?.code }</code></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                    <TextField variant={"outlined"} label="Raw JSON Data" value={data} onChange={(event) => setData(event.target.value)} multiline />
                    <FormControlLabel control={<Checkbox
                        checked={async}
                        onChange={(event) => setAsync(event.target.checked)}
                        inputProps={{ 'aria-label': 'Async checkbox' }}
                    />} label="Send asynchronously" />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} label="Cancel"></Button>
                    <Button onClick={handleClick} label="Send"></Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SendNotificationButton;