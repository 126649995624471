import {
  Datagrid,
  List,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar, ListButton, FilterButton,
} from "react-admin";
import {resourceManager} from "../../helpers/Resources";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import {JsonField} from "../fields/JsonField";
import React from "react";

export const showAction = () => (
  <>
    <Show actions={
      <TopToolbar>
        <ListButton />
      </TopToolbar>
    }>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="userId" />
        <TextField source="locale" />
        <TextField source="timezone" />
        <JsonField source="identifiers" />
      </SimpleShowLayout>
    </Show>
  </>
);

const filters = [
  <TextInput source="user_id" />,
];

const Actions = () => (
    <TopToolbar>
      <FilterButton />
    </TopToolbar>
);

const listAction = () => (
    <List exporter={false} actions={<Actions />} filters={filters} >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="userId" />
      <TextField source="locale" />
      <TextField source="timezone" />
      <ShowButton />
    </Datagrid>
  </List>
);

resourceManager.addResource('recipients', PermContactCalendarOutlinedIcon,{
  list: listAction,
  show: showAction,
})