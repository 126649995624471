import {Auth0Client} from "@auth0/auth0-spa-js";
import {createAuth0AuthProvider} from "./auth0AuthProvider";

export const auth0 = new Auth0Client({
    domain: "https://dev-l7xifohw7kaikja5.us.auth0.com",
    clientId: "nbRHos63qQPCIendF9AEFTihxWkX8SlA",
    cacheLocation: 'localstorage',
    authorizationParams: {
         audience: "https://dev-l7xifohw7kaikja5.us.auth0.com/api/v2/",
    },
});

export const authProvider = createAuth0AuthProvider(auth0);