import {
    Datagrid,
    List, ListButton,
    Show,
    ShowButton,
    SimpleShowLayout,
    TextField, TopToolbar,
} from "react-admin";
import {resourceManager} from "../../helpers/Resources";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";

export const showAction = () => (
  <>
      <Show actions={
          <TopToolbar>
              <ListButton />
          </TopToolbar>
      }>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="label" />
        <TextField source="adapter" />
        <TextField source="identityResolverOptions.identifier" />
        <TextField source="identityResolverOptions.dataKey" />
      </SimpleShowLayout>
    </Show>
  </>
);

export const listAction = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="label" />
      <TextField source="adapter" />
      <ShowButton />
    </Datagrid>
  </List>
);


resourceManager.addResource('recipient-resolvers', PermContactCalendarOutlinedIcon,{
    list: listAction,
    show: showAction,
})
