import {
    Create,
    Datagrid,
    Edit, EditButton,
    List, ListButton,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput, TopToolbar, ShowButton,
} from "react-admin";
import {resourceManager} from "../../helpers/Resources";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import {JsonField, JsonInput} from "../fields/JsonField";

const showAction = () => (
  <>
      <Show actions={
          <TopToolbar>
              <ListButton />
              <EditButton />
          </TopToolbar>
      }>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="label" />
        <JsonField source="additionalData" jsonString />
      </SimpleShowLayout>
    </Show>
  </>
);

const editAction = () => (
  <>
    <Edit actions={
        <TopToolbar>
            <ListButton />
            <ShowButton />
        </TopToolbar>
    }>
      <SimpleForm>
        <TextInput source="label" fullWidth />
        <JsonInput source="additionalData" jsonString />
      </SimpleForm>
    </Edit>
  </>
);

const createAction = () => (
  <>
    <Create actions={
        <TopToolbar>
            <ListButton />
        </TopToolbar>
    }>
      <SimpleForm>
        <TextInput source="label" fullWidth />
        <JsonInput source="additionalData" jsonString reactJsonOptions={{
          displayDataTypes: false,
          enableClipboard: false,
          defaultValue: {},
          name: null,
        }}  />
      </SimpleForm>
    </Create>
  </>
);

const listAction = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="label" />
    </Datagrid>
  </List>
);

resourceManager.addResource('notification-groups', NotificationsActiveOutlinedIcon,{
    list: listAction,
    create: createAction,
    edit: editAction,
    show: showAction,
})