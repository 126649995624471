import {GetListParams, HttpError} from "react-admin";
import {auth0} from "./auth/authProvider";

let host = ""
let version = ""

export function GetHost(): string {
    if (host === "") {
        host = process.env.API_HOST ?? "api-messenger.wk.ingentics.com"
    }
    return host
}

function camalize(str: string) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m: string, chr: any) => chr.toUpperCase());
}

export async function Call(method: string, uri: string, body?: any): Promise<any> {
    if (version === "") {
        version = process.env.API_VERSION ?? "v1"
    }

    let options: any = {
        method: method,
        redirect: "follow" as any,
        headers: {},
    }

    if (!await auth0.isAuthenticated()) {
        await auth0.getTokenSilently()
    }

    const token = await auth0.getIdTokenClaims()
    options.headers.Authorization = "Bearer " + token?.__raw

    if (!["HEAD", "GET"].includes(method) && body !== undefined && body !== null) {
        options["body"] = JSON.stringify(body)
    }

    if (uri.charAt(0) !== '/') {
        uri = "/" + uri
    }

    const x = fetch(`https://${GetHost()}/${version}${uri}`, options);

    return x.then(async response => {
        if (response.status === 403 || response.status === 401) {
            clearLocalStorage()
            window.location.href = "/login"
        }

        const body = await response.json() as any;

        if (response.status >= 200 && response.status < 300) {
            return body
        }

        throw new HttpError(
            body.message,
            response.status,
            body
        );
    }).catch(async (error) => {
        console.error(`An error occurred calling "${method} ${uri}": ${error}`)
        throw error
    })
}

// Extract the first property value of the response object
export function UnwrapData(resource: any) {
    return (result: any) => {
        let value: any = null;

        Object.keys(result).some(key => {
            if (result[key] !== null && result[key] !== undefined) {
                value = result[key];
            }
            return true;
        });

        return {
            data: value,
        }
    };
}

export function WrapData(resource: any, data: any): any {
    return {
        [camalize(resource.substring(0, resource.length - 1))]: data,
    }
}

export function FormatSearchResult(result: any): any {
    return {
        data: result?.data ?? [],
        total: parseInt(result?.pagination?.total, 10),
    };
}

export function BuildSearchBody(params: GetListParams) {
    return {
        filters: params.filter,
        meta: {
            maxPerPage: params.pagination.perPage,
            page: params.pagination.page,
        },
        sort: {
            [params.sort.field]: params.sort.order,
        }
    };
}

function clearLocalStorage(): void {
    for (let i = 0; i < localStorage.length; i++) {
        localStorage.removeItem(localStorage.key(i) as string);
    }
}
