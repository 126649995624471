import React from "react";
import {
  BooleanField,
  BooleanInput,
  Create, CreateButton,
  Datagrid,
  Edit, EditButton, FilterButton,
  List, ListButton,
  ReferenceField,
  ReferenceInput,
  Show, ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput, TopToolbar,
} from "react-admin";
import { ChannelListInput } from "../fields/ChannelListInput";
import {resourceManager} from "../../helpers/Resources";
import { CommentBankOutlined as CommentBankOutlinedIcon } from "@mui/icons-material";
import {JsonField, JsonInput} from "../fields/JsonField";
import ToggleTriggerEnabledButton from "../buttons/ToggleTriggerEnabledButton";

export const showAction = () => (
  <>
    <Show actions={
      <TopToolbar>
        <ToggleTriggerEnabledButton />
        <ListButton />
        <EditButton />
      </TopToolbar>
    }>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="label" />
        <TextField source="channel" />
        <BooleanField source="enabled" />
        <JsonField source="additionalData" jsonString />
        <ReferenceField source="notificationId" reference="notifications" link="show" />
        <ReferenceField source="recipientResolverId" reference="recipient-resolvers" link="show" />
      </SimpleShowLayout>
    </Show>
  </>
);

export const editAction = () => {
  return (
    <>
      <Edit actions={
        <TopToolbar>
          <ListButton />
          <ShowButton />
        </TopToolbar>
      }>
        <SimpleForm>
          <TextInput source="id" disabled fullWidth />
          <TextInput source="label" fullWidth />
          <ChannelListInput source="channel" />
          <JsonInput source="additionalData" jsonString />
          <ReferenceInput source="notificationId" reference="notifications" fullWidth />
          <ReferenceInput source="recipientResolverId" reference="recipient-resolvers" fullWidth />
        </SimpleForm>
      </Edit>
    </>
  );
};

export const createAction = () => {
  return (
    <>
      <Create actions={
        <TopToolbar>
          <ListButton />
        </TopToolbar>
      }>
        <SimpleForm>
          <TextInput source="label" fullWidth />
          <BooleanInput source="enabled" fullWidth />
          <JsonInput source="additionalData" jsonString />
          <ReferenceInput source="notificationId" reference="notifications" fullWidth />
          <ReferenceInput source="recipientResolverId" reference="recipient-resolvers" fullWidth />
          <ChannelListInput source="channel" />
        </SimpleForm>
      </Create>
    </>
  );
};

const filters = [
  <ReferenceInput source="notificationId" reference="notifications" />,
  <ReferenceInput source="recipientResolverId" reference="recipient-resolvers" />,
];

const Actions = () => (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
    </TopToolbar>
);

const listAction = () => (
    <List exporter={false} actions={<Actions />} filters={filters} >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="label" />
      <TextField source="channel" />
      <ReferenceField source="notificationId" reference="notifications" link="show" />
      <ReferenceField source="recipientResolverId" reference="recipient-resolvers" link="show" />
      <BooleanField source="enabled" />
    </Datagrid>
  </List>
);

resourceManager.addResource('triggers', CommentBankOutlinedIcon,{
  list: listAction,
  create: createAction,
  show: showAction,
  edit: editAction,
})
