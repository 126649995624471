import * as React from 'react';
import { useMutation } from 'react-query';
import { useRecordContext, Button, useNotify, useRefresh } from 'react-admin';
import { Api } from "../../provider/Api";
import { ToggleOnOutlined, NotificationsOffOutlined } from "@mui/icons-material";

const ToggleTemplatePublishButton = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();

    const published = record?.status === "PUBLISHED" ?? false

    const { mutate, isLoading } = useMutation(
        () => Api.toggleTemplatePublish(record.id, !published).then(() => {
            notify("The template has been updated successfully.");
            refresh();
        })
    );

    return <Button label={published ? "Unpublish" : "Publish"} startIcon={
        published ? <NotificationsOffOutlined /> : <ToggleOnOutlined />
    } onClick={() => mutate()} disabled={isLoading} />;
};

export default ToggleTemplatePublishButton;