import {
    Create, CreateButton,
    Datagrid,
    Edit, EditButton, FilterButton, FormDataConsumer,
    List, ListButton,
    ReferenceField,
    ReferenceInput,
    Show, ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput, TopToolbar,
} from "react-admin";
import { ChannelListInput } from "../fields/ChannelListInput";
import {resourceManager} from "../../helpers/Resources";
import CommentBankOutlinedIcon from "@mui/icons-material/CommentBankOutlined";
import {JsonField, JsonInput} from "../fields/JsonField";
import {Card} from "@mui/material";
import ToggleTemplatePublishButton from "../buttons/ToggleTemplatePublishButton";
import React from "react";

export const showAction = () => (
  <>
    <Show actions={
      <TopToolbar>
        <ToggleTemplatePublishButton />
        <ListButton />
        <EditButton />
      </TopToolbar>
    }>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="label" />
        <TextField source="channel" />
        <TextField source="status" />
        <JsonField source="discriminators" />
        <ReferenceField source="triggerId" reference="triggers" link="show" />
        <TextField source="content.content" label="Content" />
        <TextField source="emailOptions.subject" />
        <TextField source="pushOptions.title" />
      </SimpleShowLayout>
    </Show>
  </>
);

export const editAction = () => (
  <>
    <Edit actions={
      <TopToolbar>
        <ListButton />
        <ShowButton />
      </TopToolbar>
    }>
        <SimpleForm>
          <TextInput source="label" fullWidth />
          <ReferenceInput source="triggerId" reference="triggers" fullWidth />
          <TextInput source="content.content" multiline fullWidth rows={15} />

          <FormDataConsumer>
          {({ formData, ...rest }) => (
              (formData.channel === "PUSH") ?
                <TextInput source="pushOptions.title" fullWidth />
              : ((formData.channel === "EMAIL") ?
                <TextInput source="emailOptions.subject" fullWidth />
              : <Card />)
          )}
          </FormDataConsumer>

          <JsonInput source="discriminators" fullWidth />
        </SimpleForm>
    </Edit>
  </>
);

export const createAction = () => (
  <>
    <Create actions={
      <TopToolbar>
        <ListButton />
      </TopToolbar>
    }>
      <SimpleForm>
        <TextInput source="label" fullWidth />
        <ChannelListInput source="channel" />
        <ReferenceInput source="triggerId" reference="triggers" fullWidth />
        <TextInput source="content.content" fullWidth />
        <JsonInput source="discriminators" multiline fullWidth />
      </SimpleForm>
    </Create>
  </>
);

const filters = [
    <ReferenceInput source="triggerId" reference="triggers" />,
];

const Actions = () => (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
    </TopToolbar>
);

const listAction = () => (
    <List exporter={false} actions={<Actions />} filters={filters} >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="label" />
      <TextField source="channel" />
      <TextField source="status" />
      <ReferenceField source="triggerId" reference="triggers" link="show" />
    </Datagrid>
  </List>
);

resourceManager.addResource( 'templates', CommentBankOutlinedIcon,{
  list: listAction,
  create: createAction,
  show: showAction,
  edit: editAction,
})
