import * as React from 'react';
import { useMutation } from 'react-query';
import { useRecordContext, Button, useNotify, useRefresh } from 'react-admin';
import { Api } from "../../provider/Api";
import { ToggleOnOutlined, NotificationsOffOutlined } from "@mui/icons-material";

const ToggleTriggerEnabledButton = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();

    const enabled = record?.enabled ?? false

    const { mutate, isLoading } = useMutation(
        () => Api.toggleTriggerEnabled(record.id, !enabled).then(() => {
            notify("The trigger has been updated successfully.");
            refresh();
        })
    );

    return <Button label={enabled ? "Disable" : "Enable"} startIcon={
        enabled ? <NotificationsOffOutlined /> : <ToggleOnOutlined />
    } onClick={() => mutate()} disabled={isLoading} />;
};

export default ToggleTriggerEnabledButton;